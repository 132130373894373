import { Fragment } from 'react'
import styled, { useTheme } from 'styled-components'
import { StyledDivider } from '@/components/common/divider'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo } from '@/components/common/typography'
import { ScrollContainer } from '@/components/domains/etc'
import { HomeSectionTitle } from '@/components/domains/home'
import { Anchor } from '@/components/domains/home/Anchor'
import { NewProductCard } from '@/components/domains/products/new-product-card/new-product-card'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { HomeSectionTitleEnum } from '@/constants/home-section-title.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import { S3_HOST_URL } from '@/constants/legacy/constEnv'
import ROUTES from '@/constants/legacy/constRoutes'
import { LocationEnum } from '@/constants/location.enum'
import { useHomeBrandShopTracker } from '@/containers/event-tracker/home-brand-shop.tracker'
import { useCustomRouter, useIntersectionObserver } from '@/containers/hooks'
import { Base64ConverterUtil } from '@/utils/base64-converter.util'
import { transformProductTypeDefinedByFrontend } from '@/utils/product/product-card.util'
import { removeScrollbar } from '@/utils/utilCSS'
import type {
  BrandResponseDto,
  HomeSectionBrandResponseDtoV2,
  ProductsListItemResponseDto,
} from '@/apis/rest/generated/types'
import type { AdPetType } from '@/graphql/generated/schemas'
import type { GraphQLProductType } from '@/utils/product/product-card.util'

interface HomeSectionBrandProps {
  petType: AdPetType
  homeSectionItem: HomeSectionBrandResponseDtoV2
  refCallback: (node: HTMLDivElement) => void
}

export const HomeSectionBrand = ({ petType, homeSectionItem, refCallback }: HomeSectionBrandProps) => {
  const router = useCustomRouter()
  const { trackClickHomeBrandShopItemEvent } = useHomeBrandShopTracker()
  const { color } = useTheme()

  const handleClickProduct = (brand: BrandResponseDto) => {
    trackClickHomeBrandShopItemEvent({ brandId: brand.id, brandName: brand.name, type: 'Item' })
  }

  const handleClickMoreButton = (brand: BrandResponseDto) => {
    const brandId = Base64ConverterUtil.convertNumberIdToBase64ByKey({ key: 'BrandType', id: brand.id })

    router.push({
      pathname: ROUTES.BRAND_DETAIL,
      query: {
        brandId,
        location: LocationEnum.GnbHome,
      },
    })
  }

  const { brandsWithProducts } = homeSectionItem
  if (!brandsWithProducts || brandsWithProducts.length === 0) {
    return null
  }

  return (
    <div key={petType}>
      <Anchor id={HomeSectionTitleEnum.BrandShop} refCallback={refCallback} />
      <HomeSectionTitle>사료 · 간식 · 용품 추천 브랜드</HomeSectionTitle>
      <StyledScrollContainer>
        <StyledGridBlockDiv>
          {brandsWithProducts.map(({ brand, products }, index) => (
            <ProductWithBrand
              key={brand.id}
              index={index}
              brand={brand}
              products={products}
              onClickProductCard={() => handleClickProduct(brand)}
              onClickMoreButton={() => handleClickMoreButton(brand)}
            />
          ))}
        </StyledGridBlockDiv>
      </StyledScrollContainer>
      <StyledDivider height="0.1rem" backgroundColor={color.gray70} />
    </div>
  )
}

interface ProductWithBrandProps {
  index: number
  brand: BrandResponseDto
  products: ProductsListItemResponseDto[]
  onClickProductCard: (brand: BrandResponseDto) => void
  onClickMoreButton: (brand: BrandResponseDto) => void
}

const ProductWithBrand = ({ index, brand, products, onClickProductCard, onClickMoreButton }: ProductWithBrandProps) => {
  const { color } = useTheme()
  const { trackViewHomeBrandShopBrandEvent } = useHomeBrandShopTracker()

  const { elementRef } = useIntersectionObserver(
    () => trackViewHomeBrandShopBrandEvent({ brandId: brand.id, brandName: brand.name, type: 'Brand' }),
    { rootMargin: '0px', threshold: 1 }
  )

  return (
    <StyledProductBlockDiv ref={elementRef}>
      {brand.homeImagePath && (
        <StyledBannerImg
          alt={brand.name || `home-section-brand-name-${index}`}
          src={`${S3_HOST_URL}/${brand.homeImagePath}`}
        />
      )}
      <StyledProductWrapperDiv>
        {products.map((product, index, ar) => {
          const data = transformProductTypeDefinedByFrontend(product as unknown as GraphQLProductType)

          return (
            <Fragment key={product.id}>
              <NewProductCard
                product={data}
                size="md"
                cartType="HORIZONTAL"
                onClickProductCard={() => onClickProductCard(brand)}
              />
              {index < ar.length - 1 && <BrandStyledDivider height="0.1rem" backgroundColor={color.gray70} />}
            </Fragment>
          )
        })}
      </StyledProductWrapperDiv>
      <StyledMoreButton type="button" onClick={() => onClickMoreButton(brand)}>
        <Typo variant="Body2 Medium" color={GlobalColorEnum.Gray900}>
          {brand.name ? `${brand.name} 더 보기` : '더 보기'}
        </Typo>
        <FlatIcon type={IconNameEnum.IcChevronRight} size={IconSizeEnum.Size16} />
      </StyledMoreButton>
    </StyledProductBlockDiv>
  )
}

const StyledScrollContainer = styled(ScrollContainer)`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`

const StyledGridBlockDiv = styled.div`
  ${removeScrollbar};
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr;
  grid-column-gap: 1.2rem;
  padding-bottom: 2.4rem;

  & > *:first-child {
    margin-left: 1.6rem;
  }

  & > *:last-child {
    padding-right: 1.6rem;
    box-sizing: content-box;
  }
`

const StyledProductBlockDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 28.8rem;
`

const StyledMoreButton = styled.button`
  display: flex;
  height: 3.6rem;
  padding: 0.75rem 1.4rem 0.75rem 1.6rem;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  border-radius: 0.8rem;
  border: 1px solid ${GlobalColorEnum.Gray200};
  background-color: ${GlobalColorEnum.GrayWhite};
  cursor: pointer;
  margin-bottom: 1.2rem;
`

const StyledBannerImg = styled.img`
  display: flex;
  width: 100%;
  height: 8.2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  flex-shrink: 0;
  border-radius: 0.8rem;
  background: ${GlobalColorEnum.Gray50};
  object-fit: cover;
`

const PRODUCT_CARD_HEIGHTS = 36.9 // 12.3 * 3
const PRODUCT_CARD_GAPS = 5 // 2.5 * 2

const StyledProductWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: ${PRODUCT_CARD_HEIGHTS + PRODUCT_CARD_GAPS}rem;
`

const BrandStyledDivider = styled(StyledDivider)`
  margin: 1.2rem 0;
`
